import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { InstruccionesComponent } from './pages/instrucciones/instrucciones.component';
import { ChangePasswordComponent } from './pages/changepassword/changepassword.component';

import { RedireccionComponent } from './pages/redireccion/redireccion.component';

import { LoginVideoHmyComponent } from "./pages/loginvideohmy/loginvideo.component";
import { StreammingHmyComponent } from "./pages/streaminghmy/streamming.component";
import { LandingPageHmyComponent } from "./pages/landing-page-hmy/landing-page-hmy.component";
import { LandingPageFutbolEmotionComponent } from "./pages/landing-page-futbolemotion/landing-page-futbolemotion.component";
import { InstruccionesFunbolEmotionModule } from "./pages/instrucciones-futbolemotion/instrucciones-futbolemotion.module";
import { InstruccionesFunbolEmotionComponent } from "./pages/instrucciones-futbolemotion/instrucciones-futbolemotion.component";
import { StreammingFutbolEmotionComponent } from "./pages/streamingfutbolemotion/streamming.component";
import { LoginVideoFutbolEmotionComponent } from "./pages/loginvideofutbolemotion/loginvideo.component";
import { InstruccionesFlexigridComponent } from "./pages/instrucciones-flexigrid/instrucciones-flexigrid.component";
import { InstruccionesOkoruComponent } from "./pages/instrucciones-okoru/instrucciones-okoru.component";
import { LandingPageFlexigridComponent } from "./pages/landing-page-flexigrid/landing-page-flexigrid.component";
import { LandingPageOkoruComponent } from "./pages/landing-page-okoru/landing-page-okoru.component";
import { LoginVideoFlexigridComponent } from "./pages/loginvideoflexigrid/loginvideo.component";
import { LoginVideoOkoruComponent } from "./pages/loginvideookoru/loginvideo.component";
import { StreammingFlexigridComponent } from "./pages/streamingflexigrid/streamming.component";
import { StreammingOkoruComponent } from "./pages/streamingokoru/streamming.component";
import { InstruccionesAticaComponent } from "./pages/instrucciones-atica/instrucciones.component";
import { LandingPageAticaComponent } from "./pages/landing-page-atica/landing-page.component";
import { LandingPageAticaProveedoresComponent } from "./pages/landing-page-atica-proveedores/landing-page.component";
import { LoginVideoAticaComponent } from "./pages/loginvideoatica/loginvideo.component";
import { StreammingAticaComponent } from "./pages/streamingatica/streamming.component";
import { LandingPageEdemComponent } from "./pages/landing-page-edem/landing-page.component";
import { LandingPageDemoComponent } from "./pages/landing-page-demo/landing-page.component";



const routes: Routes = [
  { path: "instrucciones", component: InstruccionesComponent },
  { path: "cambiarcontraseña", component: ChangePasswordComponent },
  
  //{ path: "login-hmy",component: LoginVideoHmyComponent },
  //{ path: "video-hmy",component: StreammingHmyComponent },
  //{ path: "registro-hmy",component: LandingPageHmyComponent },
  /*{ path: "registro-futbolemotion",component: LandingPageFutbolEmotionComponent },
  { path: "futbolemotion",component: InstruccionesFunbolEmotionComponent },
  { path: "login-futbolemotion",component: LoginVideoFutbolEmotionComponent },
  { path: "video-futbolemotion",component: StreammingFutbolEmotionComponent },*/

  /*{ path: "registro-flexigrid",component: LandingPageFlexigridComponent },
  { path: "flexigrid",component: InstruccionesFlexigridComponent },
  { path: "login-flexigrid",component: LoginVideoFlexigridComponent },
  { path: "video-flexigrid",component: StreammingFlexigridComponent },*/

  /*{ path: "registro-okoru",component: LandingPageOkoruComponent },
  { path: "okoru",component: InstruccionesOkoruComponent },
  { path: "login-okoru",component: LoginVideoOkoruComponent },
  { path: "video-okoru",component: StreammingOkoruComponent },*/
 
 /* { path: "registro-atica",component: LandingPageAticaComponent },
  { path: "registro-atica-proveedores",component: LandingPageAticaProveedoresComponent },
  { path: "atica",component: InstruccionesAticaComponent },
  { path: "login-atica",component: LoginVideoAticaComponent },
  { path: "video-atica",component: StreammingAticaComponent },*/

  //{ path: "EDEM-registro",component: LandingPageEdemComponent },
  { path: "registro-master",component: LandingPageDemoComponent },

  /*{ path: "reservadelatierra",component: InstruccionesReservadelatierraComponent },
  { path: "reservadelatierra-login",component: LoginVideoReservadelatierraComponent },
  { path: "reservadelatierra-video",component: StreammingReservadelatierraComponent },
 
  { path: "inspiring-girls",component: InstruccionesGirlsComponent },
  { path: "inspiring-girls-registro",component: LandingPageGirlsComponent },
  { path: "inspiring-girls-login",component: LoginVideoGirlsComponent },
  { path: "inspiring-girls-video",component: StreammingGirlsComponent },
*/

  { path: "",component: RedireccionComponent },
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
