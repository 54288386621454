import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LandingPageDibexComponent } from "./landing-page-dibex.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [LandingPageDibexComponent],
  imports: [CommonModule, FormsModule],
  exports: [LandingPageDibexComponent]
})
export class LandingPageDibexModule {}
