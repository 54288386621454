import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../../services/user/user.service";
import { User } from "src/app/models/user";
import { NgForm } from "@angular/forms";
import Swal from "sweetalert2";
import { Response } from "../../interfaces/response.interface";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page-unizar.component.html",
  styleUrls: ["./landing-page-unizar.component.scss"]
})
export class LandingPageUnizarComponent implements OnInit {
  user: User;
  alertTitle = "Eventos Online";
  loading = false;
  eventId = 1;
  isValidPassword =false;
  isValidMail =false;
  isValidForm =false;
  isChecked=false;

  constructor(private userService: UserService) {}

  resetUserEvent() {
    //Rol 3, empresa 1, empresa 5
    this.user = new User(0, "","", "", "", "", "", "Congresista",3,1,"",5,"");

    const name = document.getElementById("name");
    const surname = document.getElementById("surname");
    const emails = document.getElementById("email");
    //const phone = document.getElementById("phone");
    //const cargo = document.getElementById("Position");
    //const website = document.getElementById("Website");
    //const companyName = document.getElementById("companyName");
    const password = document.getElementById("password");

    name.parentNode["classList"].remove("avalide");
    surname.parentNode["classList"].remove("avalide");
    //phone.parentNode["classList"].remove("avalide");
    emails.parentNode["classList"].remove("avalide");
    //cargo["value"] = "";
    //cargo.parentNode["classList"].remove("avalide");
    //website.parentNode["classList"].remove("avalide");
    //companyName.parentNode["classList"].remove("avalide");
    password.parentNode["classList"].remove("avalide");
  }

  checkValidForm(): void {
    
    const name = document.getElementById("name");
    const surname = document.getElementById("surname");
    //const emails = document.getElementById("email");
    //const phone = document.getElementById("phone");
    //const cargo = document.getElementById("Position");
    //const website = document.getElementById("Website");
    //const companyName = document.getElementById("companyName");
    //const password = document.getElementById("password");
    if (name["value"] != "" && surname["value"] != ""  && this.isValidMail && this.isValidPassword) {
      this.isValidForm=true;
    }
    else{
      this.isValidForm=false;
    }
  }

  ngOnInit(): void {
    this.resetUserEvent();
    const name = document.getElementById("name");
    const surname = document.getElementById("surname");
    const emails = document.getElementById("email");
    //const phone = document.getElementById("phone");
   // const cargo = document.getElementById("Position");
    //const website = document.getElementById("Website");
    //const companyName = document.getElementById("companyName");
    const password = document.getElementById("password");

    const expresion = /\w+@\w+\.+[a-z]/;
    const expresionUrl = /\w+\.+[a-z]/;
    const expresionPassword = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
   
    // nombre
    name.addEventListener("blur", () => {
      if (name["value"] === "") {
        name.parentNode["classList"].remove("avalide");
        name.parentNode["classList"].remove("avalide");
      } else {
        name.parentNode["classList"].add("avalide");
      }
      this.checkValidForm();
    });

    // apellidos
    surname.addEventListener("blur", () => {
      if (surname["value"] === "") {
        surname.parentNode["classList"].remove("avalide");
      } else {
        surname.parentNode["classList"].add("avalide");
      }
      this.checkValidForm();
    });

    // telefono
    /*phone.addEventListener("blur", () => {
      if (phone["value"] === "") {
        phone.parentNode["classList"].remove("avalide");
      } else if (isNaN(phone["value"])) {
        name.parentNode["classList"].remove("avalide");
      } else {
        phone.parentNode["classList"].add("avalide");
      }
      this.checkValidForm();
    });*/

    //correo
    emails.addEventListener("blur", () => {
      if (!expresion.test(emails["value"])) {
        console.log("el correo no es valido");
        emails.parentNode["classList"].remove("avalide");
        this.isValidMail=false;
        //return false;
      } else {
        console.log("el correo es valido");
        this.isValidMail=true;
        emails.parentNode["classList"].add("avalide");
      }
      this.checkValidForm();
    });

    // cargo
   /* cargo.addEventListener("blur", () => {
      if (cargo["value"] === "") {
        cargo.parentNode["classList"].remove("avalide");
        cargo.parentNode["classList"].remove("avalide");
      } else {
        cargo.parentNode["classList"].add("avalide");
      }
      this.checkValidForm();
    });*/

    // website
    /*website.addEventListener("blur", () => {
      if (!expresionUrl.test(website["value"])) {

        console.log("la url no es valido");
        website.parentNode["classList"].remove("avalide");
        //return false;
      } else {
        console.log("la url es valido");
        website.parentNode["classList"].add("avalide");
      }
      this.checkValidForm();
    });*/

     // companyName
     /*companyName.addEventListener("blur", () => {
      if (companyName["value"] === "") {
        companyName.parentNode["classList"].remove("avalide");
        companyName.parentNode["classList"].remove("avalide");
      } else {
        companyName.parentNode["classList"].add("avalide");
      }
      this.checkValidForm();
    });*/

     // password
     password.addEventListener("blur", () => {
      if (password["value"] === "") {
        this.isValidPassword=false;
        password.parentNode["classList"].remove("avalide");
      }else if(password["value"].length <8 || password["value"].length>20){
        this.isValidPassword=false;
        password.parentNode["classList"].remove("avalide");
      }else if(!expresionPassword.test(password["value"])){
        this.isValidPassword=false;
        password.parentNode["classList"].remove("avalide");
      } else {
        this.isValidPassword=true;
        password.parentNode["classList"].add("avalide");
      }
      this.checkValidForm();
    });


  }

  registerToEvent(form: NgForm) {
    this.loading = true;
    this.user.username=this.user.email;
    const checkbox = <HTMLInputElement>document.getElementById("checkBox");
    this.isChecked=checkbox.checked;

    if(!this.isValidMail){
      Swal.fire(
        this.alertTitle,
        "El correo seleccionado no es válido",
         "warning"
      );
    }else if(!this.isValidPassword){
      Swal.fire(
        this.alertTitle,
        "La contraseña debe contener entre 8 y 20 carácteres y contener al menos un simbolo y una mayúscula",
         "warning"
      );
    }
  else if(!this.isValidForm){
    Swal.fire(
      this.alertTitle,
      "Se han de rellenar todos los campos para continuar.",
       "warning"
    );
  }
  else if(this.user.companyId<4){
    Swal.fire(
      this.alertTitle,
      "Se tiene que seleccionar una empresa.",
       "warning"
    );
  }
  else if(!this.isChecked){
    Swal.fire(
      this.alertTitle,
      "Se ha de aceptar la politica de privacidad.",
       "warning"
    );
  }
    else{
    this.userService.registerUserToEvent(this.user, this.eventId).subscribe(
      (resp: Response) => {
        if (resp.success) {
          Swal.fire(
            this.alertTitle,
            "Tu cuenta se ha creado correctamente. Se le ha enviado un correo con las instrucciones. Por favor, compruebe su correo.",
            "success"
          );
          }else {
            Swal.fire(this.alertTitle, resp.message, "warning");
        }
        this.loading = false;
      },
      err => {
        console.error(err);
        if(err.message.includes("409 Conflict")){
          Swal.fire(this.alertTitle,"El correo utilizado ya existe", "warning");
        }
        else{
          Swal.fire(this.alertTitle, err.message+" : " + err.error_code, "warning");
        }
      
      },
      () => {
        this.resetUserEvent();
      }
    );
    }
  }
}
