import { Component, OnInit } from "@angular/core";

@Component({
  selector: "redireccion",
  templateUrl: "./redireccion.component.html",
  styleUrls: ["./redireccion.component.scss"]
})
export class RedireccionComponent  {


  reloadPage() {
    window.location.reload();
  }
}
