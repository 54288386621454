import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChangePasswordComponent } from "./changepassword.component";
import {FormsModule } from "@angular/forms";

@NgModule({
  declarations: [ChangePasswordComponent],
  imports: [CommonModule, FormsModule],
  exports: [ChangePasswordComponent]
})
export class ChangePasswordModule {}
