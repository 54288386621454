import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { Password } from 'src/app/models/password';
import { UserService } from 'src/app/services/user/user.service';
import { Response } from "../../interfaces/response.interface";

@Component({
  selector: "app-ChangePassword",
  templateUrl: "./changepassword.component.html",
  styleUrls: ["./changepassword.component.scss"]
})
export class ChangePasswordComponent implements OnInit {
  password: Password;
  isValidPassword=false;
  diferentPassword=false;
  isValidMail=false;
  alertTitle = "Eventos Online";

  

  constructor(private activatedRoute: ActivatedRoute,
    private userService: UserService) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const code = params['code'];
      const mail = params['email'];
      this.password = new Password(mail,code,"","");
      this.resetPasswordEvent();
      //this.CheckCode(code);
      //this.CheckMail(mail);
     /* Swal.fire(
        "Pita",
        id+" : "+mail
      );*/
    });
  }

  CheckMail(value: string =null ){
    const email = document.getElementById("email");
    if(value==null){
      value =email["value"];
    }
    const expresion = /\w+@\w+\.+[a-z]/;
    if (!expresion.test(value)) {
      console.log("el correo no es valido");
      email.parentNode["classList"].remove("avalide");
      this.isValidMail=false;
    } else {
      this.isValidMail=true;
      email.parentNode["classList"].add("avalide");
    }
  }

  CheckCode(value: string =null ){
    const code = document.getElementById("code");
    if(value==null){
      value =code["value"];
    }
    if (value === "" || value.length !=6) {
      code.parentNode["classList"].remove("avalide");
      code.parentNode["classList"].remove("avalide");
    } else {
      code.parentNode["classList"].add("avalide");
    }
  }

  CheckPassword(){
    const password = document.getElementById("password");
    const password2 = document.getElementById("password2");
    const expresionPassword = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

    this.diferentPassword=false;
    if (password["value"] === "") {
      this.isValidPassword=false;

    }else if(password["value"].length <8 || password["value"].length>20){
      this.isValidPassword=false;

    }else if(!expresionPassword.test(password["value"])){
      this.isValidPassword=false;

    }else if(password["value"]!=password2["value"]){
      this.isValidPassword=false;
      this.diferentPassword=true;
     
    } else {
      this.isValidPassword=true;
    
    }
    if(this.isValidPassword){
      password.parentNode["classList"].add("avalide");
      password2.parentNode["classList"].add("avalide");
    }
    else{
      password.parentNode["classList"].remove("avalide");
      password2.parentNode["classList"].remove("avalide");
    }
  }

  resetPasswordEvent() {
    //const email = document.getElementById("email");
    //const code = document.getElementById("code");
    const password = document.getElementById("password");
    const password2 = document.getElementById("password2");

    const expresionPassword = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    
    //email.parentNode["classList"].remove("avalide");
    //code.parentNode["classList"].remove("avalide");
    password.parentNode["classList"].remove("avalide");
    password2.parentNode["classList"].remove("avalide");
    
    //correo
    /*email.addEventListener("blur", () => {
      this.CheckMail();
    });

    code.addEventListener("blur", () => {
     this.CheckCode();
    });*/

    password.addEventListener("blur", () => {
     this.CheckPassword();
    });

   password2.addEventListener("blur", () => {
        this.CheckPassword();
    });
  }


  reloadPage() {
    window.location.reload();
  }

  changePassword(form: NgForm) {
   /* if(!this.isValidMail){
      Swal.fire(
        this.alertTitle,
        "El correo seleccionado no es válido",
         "warning"
      );
    }else */
    if(!this.isValidPassword){
      if(this.diferentPassword){
        Swal.fire(
          this.alertTitle,
          "No coinciden las contraseñas",
           "warning"
        );
      }
      else{
        Swal.fire(
          this.alertTitle,
          "La contraseña debe contener entre 8 y 20 carácteres y contener al menos un simbolo y una mayúscula",
           "warning"
        );
      }
    }
    else{
      this.userService.changeUserPassword(this.password).subscribe(
        (resp: Response) => {
            Swal.fire(
              this.alertTitle,
              "Tu contraseña se ha cambiado correctamente.",
              "success"
            );
        },
        err => {
          console.error(err);
          if(err.message.includes("504 Gateway Timeout")){
            Swal.fire(this.alertTitle,"Se ha caducado su código", "warning");
          }
          else if(err.message.includes("403 Forbidden")){
            Swal.fire(this.alertTitle,"Su código no es válido", "warning");
          }
          else{
            Swal.fire(this.alertTitle,"No se ha podido realizar el cambio de contraseña", "warning");
          }
        },
        () => {
          this.resetPasswordEvent();
        }
      );
    
    }
  }
}
