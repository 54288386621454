import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LandingPageUnizarComponent } from "./landing-page-unizar.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [LandingPageUnizarComponent],
  imports: [CommonModule, FormsModule],
  exports: [LandingPageUnizarComponent]
})
export class LandingPageUnizarModule {}
