import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-instrucciones",
  templateUrl: "./instrucciones.component.html",
  styleUrls: ["./instrucciones.component.scss"]
})
export class InstruccionesComponent  {


  reloadPage() {
    window.location.reload();
  }
}
