export class User {
  constructor(
    public id: number,
    public name: string,
    public lastName: string,
    public email: string,
    public phone: string,
    public username: string,
    public password: string,
    public position: string,
    public roleId: number,
    public companyId: number,
    public companyName: string,
    public worldId: number,
    public bio: string,
  ) {}

  public Clone(user: User){
    this.id=user.id;
    this.name=user.name;
    this.lastName=user.lastName;
    this.email=user.email;
    this.phone=user.phone;
    this.username=user.username;
    this.password=user.password;
    this.position=user.position;
    this.roleId=user.roleId;
    this.companyId=user.companyId;
    this.companyName=user.companyName;
    this.worldId=user.worldId;
    this.bio=user.bio;
  }

}
