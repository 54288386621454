import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { authInterceptorProviders } from "./helpers/auth.interceptor";

// Routes
import { AppRoutingModule } from "./app-routing.module";

// Custom Modules
import { AppComponent } from "./app.component";
import { LandingPageModule } from "./pages/landing-page/landing-page.module";
import { LoginModule } from "./pages/login/login.module";
import { EventsModule } from "./pages/events/events.module";
import { InstruccionesModule } from "./pages/instrucciones/instrucciones.module";
import { ChangePasswordModule } from "./pages/changepassword/changepassword.module";

// Redux
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { appReducers } from "./store/app.reducers";
import { environment } from "../environments/environment";
import { EffectsArray } from "./store/effects/index";
import { LandingPageUnizarModule } from './pages/landing-page-unizar/landing-page-unizar.module';
import { LandingPageDibexModule } from './pages/landing-page-dibex/landing-page-dibex.module';
import { InstruccionesUnizarModule } from './pages/instrucciones-unizar/instrucciones-unizar.module';
import { InstruccionesDi2020Module } from './pages/instrucciones-di2020/instrucciones-di2020.module';
import { RedireccionModule } from './pages/redireccion/redireccion.module';
import { LoginVideoSeasModule } from './pages/loginvideoseas/loginvideo.module';
import { StreammingSeasModule } from './pages/streammingseas/streamming.module';
import { InstCecPatrocinadorModule } from './pages/inst-cec-pratrocinador/inst-cec-pratrocinador.module';
import { InstCecPonentesModule } from './pages/inst-cec-ponentes/inst-cec-ponentes.module';
import { InstCecVisitanteModule } from './pages/inst-cec-visitante/inst-cec-visitante.module';
import { InstruccionesSeasModule } from './pages/inst-seas/inst-seas.module';
import { LandingPageSeasModule } from './pages/landing-page-seas/landing-page-seas.module';
import { LoginVideoCecModule } from './pages/loginvideocec/loginvideo.module';
import { StreammingCecModule } from './pages/streammingcec/streamming.module';
import { InstruccionesReservadelatierraComponent } from './pages/inst-reservadelatierra/inst-reservadelatierra.component';
import { InstruccionesColgateModule } from './pages/inst-colgate/inst-colgate.module';
import { InstruccionesGirlsModule } from './pages/inst-girls/inst-girls.module';
import { LandingPageGirlsModule } from './pages/landing-page-girls/landing-page-girls.module';
import { InstruccionesReservadelatierraModule } from './pages/inst-reservadelatierra/inst-reservadelatierra.module';
import { LoginVideoReservadelatierraModule } from "./pages/loginvideoreservadelatierra/loginvideo.module";
import { StreammingReservadelatierraModule } from "./pages/streammingreservadelatierra/streamming.module";
import { LoginVideoGirlsModule } from "./pages/loginvideogirls/loginvideo.module";
import { StreammingGirlsModule } from "./pages/streamminggirls/streamming.module";
import { LoginVideoHmyModule } from "./pages/loginvideohmy/loginvideo.module";
import { StreammingHmyModule } from "./pages/streaminghmy/streamming.module";
import { LandingPageHmyModule } from "./pages/landing-page-hmy/landing-page-hmy.module";
import { LandingPageFutbolEmotionModule } from "./pages/landing-page-futbolemotion/landing-page-futbolemotion.module";
import { InstruccionesFunbolEmotionModule } from "./pages/instrucciones-futbolemotion/instrucciones-futbolemotion.module";
import { LoginVideoFutbolEmotionModule } from "./pages/loginvideofutbolemotion/loginvideo.module";
import { StreammingFutbolEmotionModule } from "./pages/streamingfutbolemotion/streamming.module";
import { InstruccionesFlexigridModule } from "./pages/instrucciones-flexigrid/instrucciones-flexigrid.module";
import { InstruccionesOkoruModule } from "./pages/instrucciones-okoru/instrucciones-okoru.module";
import { LandingPageFlexigridModule } from "./pages/landing-page-flexigrid/landing-page-flexigrid.module";
import { LandingPageOkoruModule } from "./pages/landing-page-okoru/landing-page-okoru.module";
import { LoginVideoFlexigridModule } from "./pages/loginvideoflexigrid/loginvideo.module";
import { LoginVideoOkoruModule } from "./pages/loginvideookoru/loginvideo.module";
import { StreammingFlexigridModule } from "./pages/streamingflexigrid/streamming.module";
import { StreammingOkoruModule } from "./pages/streamingokoru/streamming.module";
import { LandingPageAticaModule } from "./pages/landing-page-atica/landing-page.module";
import { InstruccionesAticaModule } from "./pages/instrucciones-atica/instrucciones.module";
import { LandingPageAticaProveedoresModule } from "./pages/landing-page-atica-proveedores/landing-page.module";
import { StreammingAticaModule } from "./pages/streamingatica/streamming.module";
import { LoginVideoAticaModule } from "./pages/loginvideoatica/loginvideo.module";
import { LandingPageEdemModule } from "./pages/landing-page-edem/landing-page.module";
import { LandingPageDemoModule } from "./pages/landing-page-demo/landing-page.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LandingPageModule,
    LandingPageUnizarModule,
    LandingPageDibexModule,
    LoginModule,
    EventsModule,
    
    InstruccionesModule,
    ChangePasswordModule,
    
    //InstruccionesUnizarModule,
    RedireccionModule,
    
    /*//SEAS
    LandingPageSeasModule,
    InstruccionesSeasModule,
    StreammingSeasModule,
    LoginVideoSeasModule,

    //CEC
    InstCecPatrocinadorModule,
    InstCecPonentesModule,
    InstCecVisitanteModule,
    StreammingCecModule,
    LoginVideoCecModule,*/

    //HMY
    /*LoginVideoHmyModule,
    StreammingHmyModule,
    LandingPageHmyModule,*/

    //FutbolEmotion
    /*LandingPageFutbolEmotionModule,
    InstruccionesFunbolEmotionModule,
    LoginVideoFutbolEmotionModule,
    StreammingFutbolEmotionModule,*/

    /*LandingPageAticaModule,
    LandingPageAticaProveedoresModule,
    InstruccionesAticaModule,
    LoginVideoAticaModule,
    StreammingAticaModule,

    LandingPageEdemModule,*/
    LandingPageDemoModule,

   /* LandingPageOkoruModule,
    InstruccionesOkoruModule,
    LoginVideoOkoruModule,
    StreammingOkoruModule,*/

    /*LandingPageFlexigridModule,
    InstruccionesFlexigridModule,
    LoginVideoFlexigridModule,
    StreammingFlexigridModule,*/

    //Reserva de la tierra
    /*InstruccionesReservadelatierraModule,
    LoginVideoReservadelatierraModule,
    StreammingReservadelatierraModule,
 

    InstruccionesGirlsModule,
    LandingPageGirlsModule,
    LoginVideoGirlsModule,
    StreammingGirlsModule,*/
    
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot(EffectsArray),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    })
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule {}
