import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LandingPageDemoComponent } from "./landing-page.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [LandingPageDemoComponent],
  imports: [CommonModule, FormsModule],
  exports: [LandingPageDemoComponent]
})
export class LandingPageDemoModule {}
