import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RedireccionComponent } from "./redireccion.component";
import {FormsModule } from "@angular/forms";

@NgModule({
  declarations: [RedireccionComponent],
  imports: [CommonModule, FormsModule],
  exports: [RedireccionComponent]
})
export class RedireccionModule {}
