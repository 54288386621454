import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InstruccionesComponent } from "./instrucciones.component";
import {FormsModule } from "@angular/forms";

@NgModule({
  declarations: [InstruccionesComponent],
  imports: [CommonModule, FormsModule],
  exports: [InstruccionesComponent]
})
export class InstruccionesModule {}
